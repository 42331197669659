import axios, {api2} from '../../utils/axios';
import {createSlice} from '@reduxjs/toolkit';
import {startDialog, closeDialog, ErrorDialog} from './errorDialog';

const initialState = {
    isLoading: false,
    error: false,
    errorDetails: {},
    status: '',
    currentProvisioning: {},
    notifications: null,
    objects: [],
    idSelected: -1
};

const slice = createSlice({
    name: 'provisioning',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = true;
            state.errorDetail = action.payload;
        },

        // RESET ERROR
        resetError(state, action) {
            state.isLoading = false;
            state.error = false;
            state.errorDetail = {};
        },

        // SET SAVED ASSET
        setProvisioning(state, action) {
            state.isLoading = false;
            state.currentProvisioning = action.payload;
        },

        setProvisioningList(state, action) {
            state.isLoading = false;
            state.provisioningList = action.payload;
        },

        setIdSelected(state, action) {
            state.isLoading = false;
            state.idSelected = action.payload;
        },
        stopLoading(state) {
            state.isLoading = false;
        },
        onChangeStatus(state, action) {
            state.status = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {resetError, startLoading, setIdSelected, setProvisioning} = slice.actions;

// DEFAULTS

const errorDialog = new ErrorDialog();
errorDialog.title = 'Error';
errorDialog.msg = 'Error';
errorDialog.acceptBtnMsg = 'Close';

export function deleteProvisioning(id) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            // TODO: handle response.
            await axios.delete(`/api/provisioning/${id}`);
            dispatch(getProvisioningsList(null));
        } catch (error) {
            errorDialog.acceptBtnClickFn = (evt) => {
                dispatch(closeDialog());
            };
            dispatch(startDialog(errorDialog));
        }
    };
}

export function getProvisioning() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/provisioning`);
            const a = response.data.provisioning
            a['isAlive'] = response.data.isAlive
            dispatch(slice.actions.setProvisioning(response.data.provisioning));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProvisioningById(id) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/provisioning/${id}`);
            dispatch(slice.actions.setProvisioning(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProvisioningsList() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/provisioning/search/_all_`);
            dispatch(slice.actions.setProvisioningList(response.data.data));
        } catch (error) {
            errorDialog.msg = 'Error creating or updating a provisioning';
            errorDialog.acceptBtnClickFn = () => {
                dispatch(closeDialog());
            };
            dispatch(startDialog(errorDialog));
        }
    };
}

export function startProvisioning(body, templateId, callOnSubmitted) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            await api2.post(`/api/v1/tasks/run/${templateId}`, { environment: { json: JSON.stringify(body) } });
            callOnSubmitted(true);
        } catch (error) {
            errorDialog.msg = 'Error creating or updating a provisioning';
            errorDialog.acceptBtnClickFn = () => {
                dispatch(closeDialog());
            };
            dispatch(startDialog(errorDialog));
            callOnSubmitted(false);
        }
    };
}
