import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import productReducer from './slices/product';
import authJwtReducer from './slices/authJwt';
import notificationsReducer from './slices/notifications';
import settingsReducer from './slices/settings';
import assetReducer from './slices/assets';
import policiesReducer from './slices/policies';
import templatesReducer from './slices/templates';
import policyGroupsReducer from './slices/policyGroups';
import credentialsReducer from './slices/credential';
import agentsReducer from './slices/agent';
import changeRequestReducer from './slices/change_request';
import issuesReducer from './slices/issues';
import groupReducer from './slices/group';
import errorDialog from './slices/errorDialog';
import userReducer from './slices/user';
import vulnerabilitiesReducer from './slices/vulnerabilities';
import bugReducer from './slices/bugs';
import componentReducer from './slices/component';
import configReducer from './slices/config';
import taskReducer from './slices/tasks';
import mailReducer from './slices/mail';
import alertReducer from './slices/dashboard/alerts';
import mainReducer from './slices/dashboard/main';
import lifecycleReducer from './slices/dashboard/lifecycle';
import remediationReducer from './slices/dashboard/remediation';
import appConfigReducer from './slices/app_config';
import fileReducer from './slices/file';
import baseTableReducer from './slices/base_table';
import snmpReducer from './slices/snmp';
import repositoryReducer from './slices/repository';
import environmentReducer from './slices/environment';
import tagsReducer from './slices/tags';
import NTasksReducer from './slices/ntask';
import commentsReducer from './slices/comments';
import workflowReducer from './slices/workflows' 
import smartAccountReducer from './slices/smartAccount';
import provisioningReducer from './slices/provisioning';

import { automationApi } from './rtk/automationApi';
import repository from './slices/repository';
// ----------------------------------------------------------------------
// TODO: persistir los paginados, en algun momento
const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const productPersistConfig = {
  key: 'product',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const assetPersistConfig = {
  key: 'asset',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['currentAsset']
};

const assetOfflinePersistConfig = {
  key: 'asset_offline',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['currentAssetOffline']
};

const authPersistConfig = {
  key: 'authJwt',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const rootReducer = combineReducers({
  app_config: appConfigReducer,
  user: userReducer,
  notifications: notificationsReducer,
  errorDialog: errorDialog,
  credential: credentialsReducer,
  change_request: changeRequestReducer,
  issue: issuesReducer,
  base_table: baseTableReducer,
  group: groupReducer,
  policies: policiesReducer,
  templates: templatesReducer,
  pg: policyGroupsReducer,
  bug: bugReducer,
  component: componentReducer,
  vulnerability: vulnerabilitiesReducer,
  settings: settingsReducer,
  config: configReducer,
  mail: mailReducer,
  main: mainReducer,
  alert: alertReducer,
  agent: agentsReducer,
  lifecycle: lifecycleReducer,
  remediation: remediationReducer,
  task: taskReducer,
  file: fileReducer,
  api: automationApi.reducer,
  asset: persistReducer(assetPersistConfig, assetReducer),
  product: persistReducer(productPersistConfig, productReducer),
  authJwt: persistReducer(authPersistConfig, authJwtReducer),
  snmp: snmpReducer,
  repository: repositoryReducer,
  environment: environmentReducer,
  tags: tagsReducer,
  ntask: NTasksReducer,
  comments: commentsReducer,
  workflows: workflowReducer,
  smartAccount: smartAccountReducer,
  provisioning: provisioningReducer
});

export { rootPersistConfig, rootReducer };
