import NavItem from './NavItem';
import PropTypes from 'prop-types';
import Logo from '../../../components/Logo';
import useAuth from '../../../hooks/useAuth';
import React, { useEffect } from 'react';
import MyAvatar from '../../../components/MyAvatar';
import Chip from '../../../custom_components/Chip';
import Scrollbars from '../../../components/Scrollbars';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import { alpha, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Link,
  List,
  Drawer,
  Hidden,
  Typography,
  Popover
} from '@material-ui/core';
import { MIcon } from '../../../theme';
import { PATH_APP } from '../../../routes/paths';

const DRAWER_WIDTH = 280;

const useStyles = makeStyles((theme) => {
  const isLight = theme.palette.mode === 'light';

  return {
    drawer: {
      [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
      }
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
      background: theme.palette.background.default
    },
    subHeader: {
      ...theme.typography.overline,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      color: theme.palette.text.primary
    },
    account: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2, 2.5),
      margin: theme.spacing(1, 2.5, 5),
      borderRadius: theme.shape.borderRadiusSm,
      background: theme.palette.grey[isLight ? 200 : 800]
    },
    doc: {
      padding: theme.spacing(2.5),
      borderRadius: theme.shape.borderRadiusMd,
      backgroundColor: isLight
        ? alpha(theme.palette.primary.main, 0.08)
        : theme.palette.primary.lighter
    }
  };
});

// ----------------------------------------------------------------------

function reduceChild({ array, item, pathname, level }) {
  const key = item.href + level;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false
    });
    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        icon={<MIcon src={item.icon} />}
        info={item.info}
        href={item.href}
        disabled={!item?.enabled}
        title={item.title}
        open={Boolean(match)}
      >
        {renderNavItems({
          pathname,
          level: level + 1,
          items: item.items
        })}
      </NavItem>
    ];
  } else {
    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        href={item.href}
        // Este icon tira undefined
        // icon={<MIcon src={item.icon} />}
        info={item.info}
        disabled={item?.disabled}
        title={item.title}
      />
    ];
  }
  return array;
}

function renderNavItems({ items, pathname, level = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (array, item) => reduceChild({ array, item, pathname, level }),
        []
      )}
    </List>
  );
}

NavBar.propTypes = {
  onCloseNav: PropTypes.func,
  isOpenNav: PropTypes.bool
};

function NavBar({ isOpenNav, onCloseNav }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { user, menu } = useAuth();

  useEffect(() => {
    if (isOpenNav && onCloseNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbars>
      <Box sx={{ px: 2.5, py: 3 }}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Typography
          align="right"
          variant="subtitle2"
          sx={{ color: 'text.primary', fontSize: '0.5em' }}
        >
         v4.2.2.3491  trial {/*{process.env.NODE_ENV.substring(0, 3)}*/}
        </Typography>
      </Box>

      <Link underline="none" component={RouterLink} to={PATH_APP.general.main}>
        <div className={classes.account}>
          <MyAvatar />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {user.displayName}
            </Typography>
            {/* <ShowListInChips
              key={'roles'}
              list={user.roles}
              chipName="roles"
              listLabel={'Assigned roles:'}
              showFn={(it) => {
                return `${it.role}`;
              }}
            />
            <ShowListInChips
              key={'groups'}
              list={user.groups}
              chipName="groups"
              listLabel={'Assigned groups:'}
              showFn={(it) => {
                return `${it.name}`;
              }}
            /> */}
          </Box>
        </div>
      </Link>

      {menu &&
        menu.map((list) => (
          <List disablePadding key={list.subheader}>
            {renderNavItems({
              items: list.items,
              pathname: pathname
            })}
          </List>
        ))}
    </Scrollbars>
  );

  return (
    <nav className={classes.drawer}>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={isOpenNav}
          variant="temporary"
          onClose={onCloseNav}
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          anchor="left"
          variant="persistent"
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </nav>
  );
}

ShowListInChips.propTypes = {
  list: PropTypes.array,
  listLabel: PropTypes.string,
  chipLabel: PropTypes.string,
  showFn: PropTypes.func
};

function ShowListInChips({ list, listLabel, chipName, showFn }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Chip
        name={chipName}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ m: 1, color: 'text.primary' }} variant="subtitle2">
          {listLabel}
          {list?.map((it) => {
            return (
              <Typography key={it.id} sx={{ p: 1 }}>
                <Chip name={showFn(it)} />
              </Typography>
            );
          })}
        </Typography>
      </Popover>
    </>
  );
}

export default NavBar;
